import { oss_svg_image_domain_address } from '@/constants/oss'
import ReactPlayer from 'react-player'
import { t } from '@lingui/macro'
import { useMediaQuery } from 'react-responsive'
import { useEffect, useState } from 'react'
import styles from './index.module.css'

export function Banner() {
  const isMobileView = useMediaQuery({
    maxWidth: 1024,
  })
  // use a state to handle client side & server side mismatch for device width
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isMobileView)
  }, [isMobileView])
  return (
    <div className={styles.banner}>
      {!isMobile ? (
        <ReactPlayer
          playing
          loop
          muted
          controls={false}
          height={'100%'}
          width={'100%'}
          url={`${oss_svg_image_domain_address}vanshi-banner_600.mp4`}
        />
      ) : (
        <div
          className="h5-bg-img"
          style={{ background: `center/cover no-repeat url(${oss_svg_image_domain_address}h5_home_banner.png)` }}
        />
      )}
      <div className="content">
        <h2 className="title">{t`features_home_banner_pxxtlpydxw`}</h2>
        <h3 className="subtitle">{t`features_home_banner_7_1ujrtfo_`}</h3>
      </div>
    </div>
  )
}
