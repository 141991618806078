import { map } from 'lodash'
import { oss_svg_image_domain_address } from '@/constants/oss'
import LazyImage, { Type } from '@/components/lazy-image'
import { t } from '@lingui/macro'
import Icon from '@/components/icon'
import { baseCommonStore } from '@/store/common'
import styles from './index.module.css'

const getExpertiseData = () => [
  {
    title: t`features_home_expertise_ipg3oubhsh`,
    description: t`features_home_expertise_grphceq9ia`,
    icon: 'communication',
    onClick: () => {
      window.open(`https://im.vanshi.com/?locale=${baseCommonStore.getState().locale}`)
    },
  },
  {
    title: t`features_home_expertise_gbr1g4wlbs`,
    description: t`features_home_expertise_uqghvslb9v`,
    icon: 'social_contact',
  },
  {
    title: t`features_home_expertise_trdiygqpqe`,
    description: t`features_home_expertise_iqf5uqjjj0`,
    icon: 'blockchain',
  },
  {
    title: t`features_home_expertise_aul0kdt40q`,
    description: t`features_home_expertise_z5xlsmfgs9`,
    icon: 'saas',
  },
  {
    title: t`features_home_expertise_ib1_ecidtr`,
    description: t`features_home_expertise_fjkprsu6or`,
    icon: 'cloud_service',
  },
  {
    title: t`features_home_services_3cvnrndhjj`,
    description: t`features_home_expertise_6hvmrspww5`,
    icon: 'icon_vpn_pre',
    onClick: () => {
      window.open(`https://vpn.vanshi.com/?locale=${baseCommonStore.getState().locale}`)
    },
  },
]
export function Expertise() {
  return (
    <div className={styles.expertise}>
      <div className="title">{t`features_home_expertise_q_binwlgzq`}</div>
      <div className="item-container">
        {map(getExpertiseData(), (item, index) => (
          <div
            key={item.title}
            style={{
              gridRow: `${index + 1} / span 2`,
              gridColumn: `${index % 2 === 0 ? 1 : 2}`,
            }}
            className="item"
            onClick={() => item?.onClick?.()}
          >
            <LazyImage className="img" imageType={Type.png} src={`${oss_svg_image_domain_address}home_expertise_pre`} />
            <Icon name={item.icon} fontSize={46} className="icon " />

            <div className="name">{item.title}</div>
            <div className="description">{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
