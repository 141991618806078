import { oss_svg_image_domain_address } from '@/constants/oss'
import { Expertise } from './expertise'
import OurAdvantage from './our-advantage'
import Services from './services'
import Partnerships from './partnerships'
import { Banner } from './banner'

function Home() {
  return (
    <div>
      <Banner />
      <Services />
      <Expertise />
      <OurAdvantage />
      <Partnerships />
    </div>
  )
}

export default Home
