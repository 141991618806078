import { t } from '@lingui/macro'
import LazyImage, { Type } from '@/components/lazy-image'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { Typography } from '@nbit/arco'
import { getHomePageProps } from '@/helper/home/home-seo'

import cn from 'classnames'

import Icon from '@/components/icon'
import { BgText } from '@/components/bg-text'
import { Carousel, Statistic } from '@arco-design/web-react'
import { ReactNode, useRef } from 'react'
import { useInViewport } from 'ahooks'
import styles from './index.module.css'

// ourLists 我们的优势 mock 数据
const getOurLists = () => [
  {
    number: '100',
    title: t`features_home_our_advantage_irql6hvnmi`,
    desc: t`features_home_our_advantage_xao5rlx220`,
  },
  {
    number: '7',
    title: t`features_home_our_advantage_7f3cdppqwd`,
    desc: t`features_home_our_advantage_1fj9wvqdhs`,
  },
  {
    number: '10',
    title: t`features_home_our_advantage_o3etnzweqg`,
    desc: t`features_home_our_advantage_cuw7r8rmk4`,
  },
]

// our-item 组件
function OurItemComponent(props) {
  const { item, index } = props
  const ref = useRef(null)
  const [inViewport] = useInViewport(ref)

  return (
    <div className="our-item">
      <div ref={ref} className="our-item-number">
        {inViewport ? (
          <>
            <Statistic value={item.number} countFrom={1} countUp />
            <div>{index !== 1 && '+'}</div>
          </>
        ) : (
          item.number
        )}
      </div>
      <div className="our-item-title">{item.title}</div>
      <div className="our-item-desc">{item.desc}</div>
    </div>
  )
}

function OurAdvantage() {
  return (
    <div className={styles['our-advantage']}>
      <LazyImage imageType={Type.png} className="advantage-bg-img" src={`${oss_svg_image_domain_address}home_bg_di`} />
      <div className="our-lists">
        {getOurLists().map((item, index) => {
          return <OurItemComponent item={item} index={index} key={index} />
        })}
      </div>
    </div>
  )
}

export default OurAdvantage
