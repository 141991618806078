import { oss_svg_image_domain_address } from '@/constants/oss'
import LazyImage, { Type } from '@/components/lazy-image'
import { t } from '@lingui/macro'
import Marquee from 'react-fast-marquee'
import { useRef } from 'react'
import { useHover } from 'ahooks'
import styles from './index.module.css'

const partnershipsListTop = [
  'huawei',
  'chainstar',
  'aws',
  'google',
  'alibaba',
  'cloudflare',
  'beosin',
  'metatrust',
  'sumsub',
  'spark',
  'oracle',
  'invest',
  'fisd',
  'tidb',
  'numen',
  'istqb',
  'dev',
  'coinsdo',
]
const partnershipsListBottom = [
  'coinsdo',
  'dev',
  'istqb',
  'numen',
  'tidb',
  'fisd',
  'invest',
  'oracle',
  'spark',
  'sumsub',
  'metatrust',
  'beosin',
  'cloudflare',
  'alibaba',
  'google',
  'aws',
  'chainstar',
  'huawei',
]
function Partnerships() {
  const refMarqueeWrap = useRef(null)
  const isHovering = useHover(refMarqueeWrap)

  return (
    <div className={styles.partnerships}>
      <div className="title">{t`features_home_partnerships_dkwpd_opcb`}</div>
      <div className="marquee-wrap" ref={refMarqueeWrap}>
        <Marquee direction="right" speed={50} pauseOnHover play={!isHovering}>
          {partnershipsListTop.map(i => (
            <LazyImage
              key={i}
              className="partnership-img"
              imageType={Type.png}
              src={`${oss_svg_image_domain_address}partnerships/partnerships_${i}`}
            />
          ))}
        </Marquee>
        <Marquee direction="left" speed={50} pauseOnHover play={!isHovering}>
          {partnershipsListBottom.map(i => (
            <LazyImage
              key={i}
              className="partnership-img"
              imageType={Type.png}
              src={`${oss_svg_image_domain_address}partnerships/partnerships_${i}`}
            />
          ))}
        </Marquee>
      </div>
    </div>
  )
}

export default Partnerships
